/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createRef, RefObject, useEffect, useMemo, useState } from 'react';

import { graphql } from 'gatsby';

import CategoryGroupList from '../components/CategoryGorupList';
import CategoryList from '../components/CategoryList';
import Layout from '../components/Layout';

import 'prismjs/themes/prism-okaidia.css';

interface Props {
  data: BlogData;
  location: Location;
}

const TagsPage = ({ data, location }: Props) => {
  const {
    allMarkdownRemark: { group },
  } = data;
  const { hash } = location;

  const [groupRefs, setGroupRefs] = useState<RefObject<HTMLHeadingElement>[]>(
    []
  );

  const categoryGroups: CategoryGroup[] = useMemo(() => {
    return group.map(g => {
      const { category, edges } = g;
      const postTitles = edges.map(edge => {
        const {
          node: {
            id,
            frontmatter: { title, date, path, tags },
          },
        } = edge;
        return { id, title, date, path, tags };
      });
      return {
        category,
        postTitles,
      };
    });
  }, [group]);
  const categories: Category[] = useMemo(
    () =>
      group.map(g => {
        const { category, totalCount } = g;
        return { value: category, totalCount };
      }),
    [group]
  );

  useEffect(() => {
    let refs: RefObject<HTMLHeadingElement>[] = [];
    let length = categories.length;
    for (let i = 0; i < length; i++) {
      refs.push(createRef<HTMLHeadingElement>());
    }
    setGroupRefs(refs);
  }, [categories]);

  useEffect(() => {
    if (hash !== '' && groupRefs.length > 0) {
      const ref = groupRefs.find(
        ref => ref.current && ref.current.innerText === hash.substring(1)
      );
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [groupRefs, hash]);

  return (
    <Layout>
      <div
        css={{
          margin: 'auto',
          maxWidth: 1250,
        }}
      >
        <CategoryList categories={categories} />
        <CategoryGroupList
          groupRefs={groupRefs}
          categoryGroups={categoryGroups}
        />
      </div>
    </Layout>
  );
};

export default TagsPage;

export const query = graphql`
  query {
    allMarkdownRemark {
      group(field: frontmatter___category) {
        category: fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              date(formatString: "YYYY.MM.DD")
              path
              tags
              title
            }
          }
        }
      }
    }
  }
`;
